import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

const StorageService = {
    get(key: any) {
        return Storage.get({ key: key });
    },

    set(key: any, value: any) {
        return Storage.set({
            key: key,
            value: value,
        });
    },

    remove(key: any) {
        return Storage.remove({
            key: key
        });
    },
};

export default StorageService;