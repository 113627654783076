import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import AuthService from "@/services/auth.service";
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/loading'
  },
  {
    path: '/dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/loading',
    component: () => import('../views/loading.vue')
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/reset-password',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/sales-order',
    component: () => import('../views/Order/SalesOrder.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/smart-fulfilment',
    component: () => import('../views/Order/SmartFulfilment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/purchase-order',
    component: () => import('../views/Order/PurchaseOrder.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product/:id',
    component: () => import('../views/Product/View.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product-inventory',
    component: () => import('../views/Inventory/ProductInventory.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stock-take',
    component: () => import('../views/Inventory/StockTake.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customers',
    component: () => import('../views/Customer/Customers.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart',
    component: () => import('../views/Cart/Cart.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/update/:id',
    component: () => import('../views/Cart/Update.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/select-customer',
    component: () => import('../views/Cart/SelectCustomer.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/cart/new-customer',
    component: () => import('../views/Cart/NewCustomer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/edit-customer',
    component: () => import('../views/Cart/EditCustomer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/payment',
    component: () => import('../views/Cart/Payment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/payment-gateway',
    component: () => import('../views/Cart/PaymentGateway.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/payment-cash',
    component: () => import('../views/Cart/PaymentCash.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/payment-other',
    component: () => import('../views/Cart/PaymentOtherPayment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/payment-bank-transfer',
    component: () => import('../views/Cart/PaymentBankTransfer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/order-success',
    component: () => import('../views/Cart/OrderSuccess.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/order-received',
    component: () => import('../views/Cart/OrderReceived.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart/receipt',
    component: () => import('../views/Cart/Receipt.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customer/create',
    component: () => import('../views/Customer/Create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customer/view/:id',
    component: () => import('../views/Customer/View.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions',
    component: () => import('../views/Transaction/Transactions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions/view/:id',
    component: () => import('../views/Transaction/View.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions/update-payment/:id',
    component: () => import('../views/Transaction/UpdatePayment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions/view/:id/receiving/:deliveryOrderid',
    component: () => import('../views/Transaction/Receiving.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sales-planner',
    component: () => import('../views/SalesPlanner/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sales-planner/create',
    component: () => import('../views/SalesPlanner/Create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sales-planner/edit/:id',
    component: () => import('../views/SalesPlanner/Edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sales-planner/edit/:id/add',
    component: () => import('../views/SalesPlanner/AddProduct.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sales-planner/edit/:id/edit/:detailId',
    component: () => import('../views/SalesPlanner/EditProduct.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/knowledgebase',
    component: () => import('../views/Knowledgebase.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/aafiyat-token',
    component: () => import('../views/AafiyatToken.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    component: () => import('../views/profile/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/edit',
    component: () => import('../views/profile/editProfile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notification',
    component: () => import('../views/Notification/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/coming-soon',
    component: () => import('../views/ComingSoon.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/feedback',
    component: () => import('../views/Feedback.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/opening',
    component: () => import('../views/Opening.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/BSO/checklist',
    component: () => import('../views/BSO/Checkist.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/BSO/btl',
    component: () => import('../views/BSO/BTL.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/BSO/AddBTL',
    component: () => import('../views/BSO/AddBTL.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/BSO/retention',
    component: () => import('../views/BSO/Retention.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/BSO/Addretention',
    component: () => import('../views/BSO/AddRetention.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/BSO/training',
    component: () => import('../views/BSO/Training.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/BSO/league',
    component: () => import('../views/BSO/League.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/referral',
    component: () => import('../views/Order/Referral.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/activity/btl',
    component: () => import('../views/activity/BTL.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/activity/addBtl',
    component: () => import('../views/activity/AddBTL.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/activity/training',
    component: () => import('../views/activity/Training.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/activity/league/:type',
    component: () => import('../views/activity/League.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/aafiyat-marketer',
    component: () => import('../views/Marketer/ListMarketers.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/aafiyat-marketer/create',
    component: () => import('../views/Marketer/Create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sf-order',
    component: () => import('../views/sfOrder/sfList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sf-order/viewOrder/:id',
    component: () => import('../views/sfOrder/viewOrder.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logout',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/prospect',
    component: () => import('../views/Prospect.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/special',
    component: () => import('../views/Special.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/prospect2',
    component: () => import('../views/Prospect2.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/prospect3',
    component: () => import('../views/Prospect3.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/wallet',
    component: () => import('../views/Wallet/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/wallet/viewPerCoin/:id',
    component: () => import('../views/Wallet/viewPerCoin.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    AuthService.checkAuth().then(result => {
      if (result) {
        next()
      }
      else {
        next('/login')
      }
    });
  } else {
    next()
  }
})
export default router
