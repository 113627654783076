import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
import ApiService from "@/services/api.service";
import {alertController} from "@ionic/vue";
const AuthService = {
    checkAuth() {
        return new Promise((resolve) => {
            Storage.get({ key: "access_token" }).then((response: any) => {
                if (response.value != null) {
                    ApiService.get("profile/token").then((response) => {
                        if (response.status == 401) {
                            resolve(false);
                            this.alert2("Your login session is expired. Please login again.");
                        } else if (response.status == 200) {
                            if (response.data.status === 'success') {
                                resolve(true);
                            }
                            else {
                                resolve(false);
                                this.alert2("Your account is NOT ACTIVE. Please contact the Administrator.");
                            }
                        } else {
                            resolve(false);
                            this.alert2("Something wrong with the server. Please contact the Administrator.");
                        }
                    });
                }
                else {
                    resolve(false);
                }
            });
        });
    },
    async alert2(msj:any){
        const alert = await alertController.create({
            message: msj,
        });
        return alert.present();
    }
}
export default AuthService
