import {
    cafeOutline,
    cafeSharp,
    homeOutline,
    homeSharp,
    logOutOutline,
    logOutSharp,
    radioButtonOffSharp, reorderThreeSharp, server
} from "ionicons/icons";
import ApiService from "@/services/api.service";
const menu = new Array();

const MenuService = {
     mainMenu() {

         ApiService.getToken().then((response: any) => {
             if (response.value !== 'undefined') {
                 const data = JSON.parse(response.value);
                 if (data !== null){
                     ApiService.get("getMenu").then((response) => {
                         if (response.status === 200){
                             const  getMenu = response.data;
                             if (getMenu.indexOf("dashboard") !== -1){this.dashboard()}
                             if (getMenu.indexOf("events") !== -1){this.events()}
                             if (getMenu.indexOf("special") !== -1){this.special()}
                             if (getMenu.indexOf("wallet") !== -1){this.wallet()}
                             if (getMenu.indexOf("salesOrder") !== -1){this.salesOrder()}
                             if (getMenu.indexOf("purchaseOrder") !== -1){this.purchaseOrder()}
                             if (getMenu.indexOf("smartFulfilment") !== -1){this.smartFulfilment()}
                             if (getMenu.indexOf("referralLink") !== -1){this.referralLink()}
                             if (getMenu.indexOf("queueOrder") !== -1){this.queueOrder()}
                             if (getMenu.indexOf("aafiyatMarketeer") !== -1){this.aafiyatMarketeer()}
                             if (getMenu.indexOf("activity") !== -1){this.activity()}
                             if (getMenu.indexOf("productInventory") !== -1){this.productInventory()}
                             if (getMenu.indexOf("customers") !== -1){this.customers()}
                             if (getMenu.indexOf("prospect") !== -1){this.prospect()}
                             if (getMenu.indexOf("prospect2") !== -1){this.prospect2()}
                             if (getMenu.indexOf("prospect3") !== -1){this.prospect3()}
                             if (getMenu.indexOf("transactions") !== -1){this.transactions()}
                             if (getMenu.indexOf("salesPlanner") !== -1){this.salesPlanner()}
                             if (getMenu.indexOf("knowledgeBase") !== -1){this.knowledgeBase()}
                             if (getMenu.indexOf("feedback") !== -1){this.feedback()}
                             if (getMenu.indexOf("profile") !== -1){this.profile()}
                             if (getMenu.indexOf("logout") !== -1){this.logout()}
                         }
                     });
                 }
             }
         });

        return menu;
    },
    dashboard(){
         menu.push(
             {
                 title: "Dashboard",
                 url: "/dashboard",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-home-icons.svg",
             },
         );
    },
    logout(){
         menu.push(
             {
                 title: "Logout",
                 url: "/logout",
                 iosIcon: logOutOutline,
                 mdIcon: logOutSharp,
                 src: "",
             },
         );
    },
    events(){
         menu.push(
             {
                 title: "Events",
                 url: "/BSO/checklist",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/ribbon-outline.svg",
             },
         );
    },
    salesOrder(){
         menu.push(
             {
                 title: "Sales Order",
                 url: "/sales-order",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-sales-order-icons.svg",
             },
         );
    },
    purchaseOrder(){
         menu.push(
             {
                 title: "Purchase Order",
                 url: "/purchase-order",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-purchase-order-icons.svg",
             },
         );
    },
    smartFulfilment(){
         menu.push(
             {
                 title: "Smart Ordering",
                 url: "/smart-fulfilment",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/smart-fulfilment-button-white.svg",
             },
         );
    },
    referralLink(){
         menu.push(
             {
                 title: "Referral Link",
                 url: "/referral",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/share-social-outline.svg",
             },
         );
    },
    activity(){
         menu.push(
             {
                 title: "Activity",
                 url: "/activity/btl",
                 iosIcon: homeOutline,
                 mdIcon: homeOutline,
                 src: "icons/right-animation.svg",
             },
         );
    },
    productInventory(){
         menu.push(
             {
                 title: "Product Inventory",
                 url: "/product-inventory",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-products-icons.svg",
             },
         );
    },
    aafiyatMarketeer(){
         menu.push(
             {
                 title: "Aafiyat Marketeer",
                 url: "/aafiyat-marketer",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-marketer.svg",
             },
         );
    },
    queueOrder(){
         menu.push(
             {
                 title: "Smart Fulfillment",
                 url: "/sf-order",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/fulfillment-order.svg",
             },
         );
    },
    customers(){
         menu.push(
             {
                 title: "Customers",
                 url: "/customers",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-customers-icons.svg",
             },
         );
    },
    prospect(){
         menu.push(
             {
                 title: "Leads Form",
                 url: "/prospect",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-prospect-icons.svg",
             },
         );
    },
    prospect2(){
        menu.push(
            {
                title: "My Lead",
                url: "/prospect2",
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                src: "icons/document-text-outline.svg",
            },
        );
    },
    prospect3(){
        menu.push(
            {
                title: "Prospect",
                url: "/prospect3",
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                src: "icons/git-network-outline.svg",
            },
        );
    },
    transactions(){
         menu.push(
             {
                 title: "Transactions",
                 url: "/transactions",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-transaction-icons.svg",
             },
         );
    },
    salesPlanner(){
         menu.push(
             {
                 title: "Sales Planner",
                 url: "/sales-planner",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-sales-planner-icons.svg",
             },
         );
    },
    knowledgeBase(){
         menu.push(
             {
                 title: "Knowledgebase",
                 url: "/knowledgebase",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-knowledgebase-icons.svg",
             },
         );
    },
    profile(){
         menu.push(
             {
                 title: "Profile",
                 url: "/profile",
                 iosIcon: homeOutline,
                 mdIcon: homeSharp,
                 src: "icons/menu-profile-icons.svg",
             },
         );
    },
    feedback(){
         menu.push(
             {
                 title: "Feedback",
                 url: "/feedback",
                 iosIcon: cafeOutline,
                 mdIcon: cafeSharp,
                 src: "icons/menu-feedback.svg",
             },
         );
    },
    special(){
         menu.push(
             {
                 title: "Special",
                 url: "/special",
                 iosIcon: cafeOutline,
                 mdIcon: cafeSharp,
                 src: "icons/menu-special-outline.svg",
             },
         );
    },
    wallet(){
        menu.push(
            {
                title: "Wallet",
                url: "/wallet",
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                        src: "icons/wallet.svg",
            },
        );
    },
};

export default MenuService;
