import "@capacitor-community/http";
import { Plugins } from "@capacitor/core";
const { Http, Storage } = Plugins;
const baseUrl = "https://apiagent.aafiyat2u.net/agent/"; // dev
// const baseUrl = "http://api-agents.test/agent/"; //local
// const baseUrl = "https://agent.aafiyatgroup.com/agent/"; // live
// let baseUrl : any; // for apps testing

const ApiService = {
    // async setUrl() {
    //     await Storage.get({ key: "url_testing" }).then((response: any) => {
    //         baseUrl = JSON.parse(response.value);
    //     });
    // },
    getBaseUrl() {
        return baseUrl;
    },
    getVer() {
        return '1.3.3';
    },
    getacoreurl() {
        let url: any;
        if (this.getBaseUrl() === "https://agent.aafiyatgroup.com/agent/"){
            url = 'https://acore.aafiyatgroup.com/';
        } else if (this.getBaseUrl() === "https://apiagent.aafiyat2u.net/agent/"){
            url = 'https://dev-acore.aafiyat2u.net/';
        } else {
            url = 'http://acore-project.test/';
        }
        return url;
    },
    getToken() {
        return Storage.get({ key: "access_token" });
    },
    async get(url: any) {
        // this.setUrl()
        let user: any;
        await this.getToken().then((response: any) => {
            if (response.value !== 'undefined') {
                user = JSON.parse(response.value);
            }
        });
        return await Http.request({
            method: "GET",
            url: baseUrl + url,
            headers: {
                "Accept": "application/json",
                "Authorization": 'Bearer ' + user
            }
        }).catch((error: any) => {
            console.log("error:", error);
        });
    },
    async post(url: any, data: {}) {
        // this.setUrl()
        let user: any;
        await this.getToken().then((response: any) => {
            if (response.value !== 'undefined') {
                user = JSON.parse(response.value);
            }
        });
        return await Http.request({
            method: "POST",
            url: baseUrl + url,
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + user
            },
            data: data,
        }).catch((error: any) => {
            console.log("error:", error);
        });
    },
    async put(url: any, data: {}) {
        let user: any;
        await this.getToken().then((response: any) => {
            if (response.value !== 'undefined') {
                user = JSON.parse(response.value);
            }
        });
        return await Http.request({
            method: "PUT",
            url: baseUrl + url,
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + user
            },
            data: data,
        })
    },
    async delete(url: any) {
        let user: any;
        await this.getToken().then((response: any) => {
            if (response.value !== 'undefined') {
                user = JSON.parse(response.value);
            }
        });
        return await Http.request({
            method: "DELETE",
            url: baseUrl + url,
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + user
            }
        })
    },
    async login(data: {}) {
        // this.setUrl()
        console.log(baseUrl)
        return Http.request({
            method: "POST",
            url: baseUrl + "auth/login",
            headers: {
                "Content-Type": "application/json"
            },
            data: data,
        })
    },
    // requestBin(data: {}) {
    //     return Http.request({
    //         method: "POST",
    //         url: "https://enw0uv853pdjl2p.m.pipedream.net",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: data,
    //     });
    // }
};
export default ApiService;
