import ApiService from "@/services/api.service";
import CartService from "@/services/cart.service";
import StorageService from "@/services/storage.service";
import NetworkService from "@/services/network.service";

import { createStore } from 'vuex'
export const store = createStore({
    state() {
        return {
            user: {},
            cart: {
                customer: {},
                type: "",
                total: 0,
                products: [],
            },
            cartType: "",
            numberOfItemInCart: 0,
            totalCart: 0,
            salesOrderProduct: {},
            salesOrderProductBundle: {},
            purchaseOrderProduct: {},
            smartFulfilmentProduct: {},
            cartStatus: 'start',
            camHaveBillplz: false,
            searchCustomer: {},
            productInventory: {}
        }
    },
    mutations: {
        increment(state: any) {
            state.count++
        },
        updateSalesOrderProduct(state: any, salesOrderProduct) {
            state.salesOrderProduct = salesOrderProduct;
        },
        updateSalesOrderProductBundle(state: any, salesOrderProductBundle) {
            state.salesOrderProductBundle = salesOrderProductBundle;
        },
        updatePurchaseOrderProduct(state: any, purchaseOrderProduct) {
            state.purchaseOrderProduct = purchaseOrderProduct;
        },
        updateSmartFulfilmentProduct(state: any, smartFulfilmentProduct) {
            state.smartFulfilmentProduct = smartFulfilmentProduct;
        },
        updateProductInventory(state: any, productInventory) {
            state.productInventory = productInventory;
        },
        updateCartType(state: any, cartType) {
            state.cartType = cartType;
        },
        updateLoginUser(state: any, user) {
            state.user = user;
        },
        updateNumberOfItemInCart(state: any, numberOfItemInCart) {
            state.numberOfItemInCart = numberOfItemInCart;
        },
        updateTotalCart(state: any, totalCart) {
            state.totalCart = totalCart;
        },
        updateCartStatus(state: any, status) {
            state.cartStatus = status;
        },
        updateCamHaveBillplz(state: any, camHaveBillplz) {
            state.camHaveBillplz = camHaveBillplz;
        },
        searchCustomer(state: any, searchCustomer) {
            state.searchCustomer = searchCustomer;
        }
    },
    actions: {
        async getSalesOrderProduct(context) {
            NetworkService.isOnline();
            await ApiService.get("products/sales-order").then((response: any) => {
                StorageService.set('sales_order_product', JSON.stringify(response.data.data));
                context.commit('updateSalesOrderProduct', response.data.data);
            });
        },
        async getSalesOrderProductBundle(context) {
            NetworkService.isOnline();
            await ApiService.get("products/sales-order-bundle").then((response: any) => {
                StorageService.set('sales_order_product_bundle', JSON.stringify(response.data.data));
                context.commit('updateSalesOrderProductBundle', response.data.data);
            });
        },
        async getPurchaseOrderProduct(context) {
            if (Object.keys(context.state.purchaseOrderProduct).length == 0) {
                await ApiService.get("products/purchase-order").then((response: any) => {
                    context.commit('updatePurchaseOrderProduct', response.data.data);
                });
            }
        },
        async getSmartFulfilmentProduct(context) {
            if (Object.keys(context.state.smartFulfilmentProduct).length == 0) {
                await ApiService.get("products/smart-fulfilment").then((response: any) => {
                    context.commit('updateSmartFulfilmentProduct', response.data.data);
                });
            }
        },
        async cartAddProduct(context, { productId, cartType }) {
            if (cartType == 'salesorder') {
                context.dispatch('updateCartStatus', 'adding_product');
                await CartService.addProduct(productId, cartType, context.state.salesOrderProduct, null);
                context.dispatch('updateCartStatus', 'success_adding_product');
            }
            else if (cartType == "purchaseorder") {
                if (Object.keys(context.state.user).length == 0) {
                    console.log('user empty');
                    await StorageService.get('login_user').then((response: any) => {
                        console.log('retrive from local storage');
                        if (response.value != null) {
                            const loginUser = JSON.parse(response.value);
                            context.commit('updateLoginUser', loginUser.user);
                            CartService.addProduct(productId, cartType, context.state.purchaseOrderProduct, context.state.user);
                        }
                    });
                }
                else {
                    await CartService.addProduct(productId, cartType, context.state.purchaseOrderProduct, context.state.user);
                }
            }
            else if (cartType == "smartfulfilment") {
                await CartService.addProduct(productId, cartType, context.state.smartFulfilmentProduct, null);
            }
            context.commit('updateCartType', cartType);
        },
        async cartAddProductBundle(context, { productId, cartType }) {
            if (cartType == 'salesorder') {
                context.dispatch('updateCartStatus', 'adding_product');
                await CartService.addProduct(productId, cartType, context.state.salesOrderProductBundle, null);
                context.dispatch('updateCartStatus', 'success_adding_product');
            }
            context.commit('updateCartType', cartType);
        },
        async getProductInventory(context) {
            await ApiService.get("products/product-inventory").then((response: any) => {
                context.commit('updateProductInventory', response.data.data);
            });
        },
        async searchCustomer(context, keyword: any) {
            await ApiService.post("customers/search", { 'keyword': keyword }).then((response: any) => {
                context.commit('searchCustomer', response.data.data);
            });
        },
        updateLoginUser(context, user: any) {
            context.commit('updateLoginUser', user);
        },
        updateNumberOfItemInCart(context, numberOfItemInCart: any) {
            context.commit('updateNumberOfItemInCart', numberOfItemInCart);
        },
        updateTotalCart(context, totalCart: any) {
            context.commit('updateTotalCart', totalCart);
        },
        updateCartStatus(context, status: any) {
            context.commit('updateCartStatus', status);
        },
        updateCamHaveBillplz(context, camHaveBillplz: any) {
            context.commit('updateCamHaveBillplz', camHaveBillplz);
        },
        updateCartType(context, cartType: any) {
            context.commit('updateCartType', cartType);
        },
    }
});