import { Plugins } from "@capacitor/core";
const { Network } = Plugins;

const NetworkService = {
    async isOnline(){
        await Network.getStatus().then((response: any) => {
            console.log(response);
        });
    }
};

export default NetworkService;