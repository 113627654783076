import { Plugins } from "@capacitor/core";
import { store } from "@/store";
const { Storage } = Plugins;
const CartService = {
    async get() {
        return Storage.get({ key: "cart" });
    },
    test() {
        return new Promise((resolve) => {
            Storage.get({ key: "cart" }).then((response: any) => {
                const cart = JSON.parse(
                    response.value || JSON.stringify({ products: [], total: 0 })
                );
                resolve(cart);
            });
        });
    },
    // Recalculate the cart total
    async recalculate() {
        await Storage.get({ key: "cart" }).then(async (response: any) => {
            const cart = JSON.parse(
                response.value || JSON.stringify({ products: [], total: 0 })
            );
            let total = 0;
            cart.products.forEach((product: any) => {
                total = total + (product.price * product.quantity);
            });
            cart.subtotal = total;
            if (cart.shippingCost) {
                cart.total = Number(cart.subtotal) + Number(cart.shippingCost);
            }
            else {
                cart.total = total;
            }
            store.dispatch('updateNumberOfItemInCart', cart.products.length);
            store.dispatch('updateTotalCart', total);
            await Storage.set({
                key: "cart",
                value: JSON.stringify(cart),
            });
        });
    },
    // Remove product from cart
    async remove(productId: any) {
        await Storage.get({ key: "cart" }).then(async (response: any) => {
            if (response.value != null) {
                const cart = JSON.parse(response.value);
                const index = cart.products.findIndex(function (o: any) {
                    return o.id == productId;
                });
                cart.products.splice(index, 1);
                return Storage.set({
                    key: "cart",
                    value: JSON.stringify(cart),
                }).then(() => {
                    this.recalculate();
                });
            }
        });
    },
    // Clear cart
    async clear() {
        console.log('to clear cart');
        const cart = {
            total: 0,
            payment: {},
        };
        store.dispatch('updateNumberOfItemInCart', 0);
        store.dispatch('updateTotalCart', 0);
        return Storage.set({
            key: "cart",
            value: JSON.stringify(cart),
        });
    },
    async addProduct(productId: any, cartType: any, products = [], agent: any) {
        // find product using the productId
        const product: any = products.find(function (o: any) {
            return o.id == productId;
        });
        // get cart from local storage
        await Storage.get({ key: 'cart' }).then(async (response: any) => {
            let cart: { products: any; total: number; type: string; customer: any };
            cart = {
                products: [],
                total: 0,
                type: cartType,
                customer: {}
            };
            if (store.state.cartType == cartType) {
                cart = JSON.parse(
                    response.value ||
                    JSON.stringify({
                        products: [],
                        items: 0,
                        total: 0,
                        type: cartType,
                        customer: {}
                    })
                );
            }

            // Check if product already exist in the cart
            // If yes , increase the quantity
            if (cart && cart.products) {
                const index = cart.products.findIndex(function (o: any) {
                    return o.id == product.id;
                });
                // Product is exist in the cart
                if (index != -1) {
                    cart.products[index]["quantity"] =
                        Number(cart.products[index]["quantity"]) + 1;
                    cart.products[index]['price'] = product.price;
                }
                else {
                    // push the product to the storage
                    cart.products.push({
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        image: product.image,
                        uom: product.uom,
                        quantity: 1,
                    });
                }
            }
            else {
                cart.products = [];
                // push the product to the storage
                cart.products.push({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    image: product.image,
                    uom: product.uom,
                    quantity: 1,
                });
            }

            if (cartType == "purchaseorder") {
                cart.customer = agent;
            }
            cart.type = cartType;
            // save to the cart
            await Storage.set({
                key: "cart",
                value: JSON.stringify(cart),
            }).then(() => {
                this.recalculate();
            });
        });
    },
    async updateShippingCost(shippingCost: any) {
        console.log('start updateShippingCost');
        await Storage.get({ key: "cart" }).then(async (response: any) => {
            if (response.value != null) {
                console.log(shippingCost);
                const cart = JSON.parse(response.value);
                cart.shippingCost = shippingCost;
                cart.total = Number(cart.subtotal) + Number(cart.shippingCost);
                console.log(cart);
                await Storage.set({
                    key: "cart",
                    value: JSON.stringify(cart),
                });
            }
        });
    },
    async updateVoucher(voucher: any) {
        console.log('start updateVoucher');
        await Storage.get({ key: "cart" }).then(async (response: any) => {
            if (response.value != null) {
                console.log(voucher);
                const cart = JSON.parse(response.value);
                cart.voucher = voucher;
                if (voucher.discount_type === 1){
                    cart.total = Number(cart.subtotal) - Number(cart.voucher.amount);
                } else if (voucher.discount_type === 0){
                    cart.total = Number(cart.subtotal) * ((100 - Number(cart.voucher.amount))/100);
                }
                await Storage.set({
                    key: "cart",
                    value: JSON.stringify(cart),
                });
            }
        });
    },
    async updateMembership(membership: any) {
        console.log('start updateMembership');
        await Storage.get({ key: "cart" }).then(async (response: any) => {
            if (response.value != null) {
                console.log(membership);
                const cart = JSON.parse(response.value);
                cart.membership = membership;
                await Storage.set({
                    key: "cart",
                    value: JSON.stringify(cart),
                });
            }
        });
    },
    getTotalCart(cartType = "salesorder") {
        if (store.state.cartType == cartType) {
            if (isNaN(store.state.totalCart)) {
                return 0;
            }
            return this.numberFixed(store.state.totalCart);
        } else return 0;
    },
    getNumberOfItemInCart(cartType = "salesorder") {
        if (store.state.cartType == cartType)
            return store.state.numberOfItemInCart;
        else return 0;
    },
    numberFixed(number: any) {
        return Number(number).toFixed(2);
    },
};

export default CartService