
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";
import { useRoute } from "vue-router";
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  homeOutline,
  homeSharp,
  logOutOutline,
  logOutSharp,
  cafeOutline,
  cafeSharp,
} from "ionicons/icons";
import { Plugins } from "@capacitor/core";
import MenuService from "@/services/menu.service";
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const store = useStore();
    const { Storage } = Plugins;
    const selectedIndex = ref(0);
    const appPages = MenuService.mainMenu();

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }

    const route = useRoute();

    return {
      store,
      Storage,
      selectedIndex,
      appPages,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      homeOutline,
      homeSharp,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
    };
  },
  mounted() {
    console.log("App mounted");
  },
  methods: {
    ionViewDidEnter() {
      console.log("App ionViewDidEnter");
    },
  },
  data() {
    return {
    };
  },
});
